import React, { useState } from "react";
import { useAuth } from "@/hooks/useAuth";
import Autocomplete from "./Autocomplete";
import { useNavigate } from "react-router-dom";
import NotificationCenter from "./Notifications/NotificationCenter";
import useElastic from "../hooks/useElastic";
import { formatCurrency } from "../utils/format";
import keywordBlacklist from "../utils/keywordBlacklist";
import { bookStatus, orderStatus, personTypes } from "../utils/types";
import BookStatus from "./BookStatus";
import Dropdown from "./Dropdown";
import { hyphenate } from 'isbn3';
import ProfileDialog from "./ProfileDialog";

export default (props) => {
    const { user, logout, publishers } = useAuth();
    const navigate = useNavigate();
    const { search } = useElastic();
    const [profileDialogOpen, setProfileDialogOpen] = useState(false);

    const handleLogout = () => {
        logout();
    }
 
    return (
        <div className="flex items-center w-full h-16 lg:h-20 px-4 lg:px-8 shadow z-10 bg-white">

            <button className="text-slate-500 focus:outline-none xl:hidden" onClick={props.onOpenSidebar}>
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>


            <div className="flex-1 ml-3 xl:ml-0 max-w-[500px] relative z-50">
                <Autocomplete
                    placeholder="Suchbegriffe eingeben..."
                    debounceTime="500"
                    className={'w-full lg:text-lg border-gray-300 relative z-0 rounded-full px-4 py-2 pl-12 focus:border-gray-400 outline-none shadow-inner transition-all'}
                    menuClassName="!w-[500px] !max-h-[70vh]"
                    onSelect={(value) => {
                        if (value._index == 'books') {
                            navigate(`/books/view/${value._id}`, { replace: true });
                        } else if (value._index == 'persons') {
                            navigate(`/persons/view/${value._id}`);
                        } else if (value._index == 'orders') {
                            navigate(`/orders/view/${value._id}`);
                        } else if (value._index == 'invoices') {
                            if (value._source.order_ids.length > 0) {
                                const order_id = value._source.order_ids[0];
                                navigate(`/orders/view/${order_id}`);
                            }
                        }
                    }}
                    onSearch={async (query) => {
                        const results = await search({
                            index: ['persons', 'orders', 'books', 'invoices'],
                            body: {
                                min_score: 10,
                                query: {
                                    bool: {
                                        should: [
                                            ...query.trim().split(' ')
                                                .filter(word => !keywordBlacklist.includes(word))
                                                .map(word => [
                                                    // { query_string: { query: word } },
                                                    { term: { invoice_id: { value: word, boost: 10 } } },
                                                    { term: { _id: { value: word, boost: 10 } } },
                                                    { term: { client_name: { value: word, boost: 1 } } },
                                                    { term: { verkehrsnummer: { value: word, boost: 10 } } },
                                                    { fuzzy: { authors: { value: word, boost: 2 } } },
                                                    { wildcard: { isbn13: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 150 } } },
                                                    { wildcard: { isbn10: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 150 } } },
                                            ]).flat(),
                                            { match: { full_name: { query, boost: 10, fuzziness: "AUTO" } } },
                                            { match: { title: { query, boost: 8 } } },
                                            { match: { code: { query, boost: 12 } } }
                                        ],
                                        must: [
                                            { terms: { publisher_id: publishers.map(publisher => publisher.id) } }
                                        ]
                                    }
                                },
                                size: 10
                            },
                        });

                        return results.hits?.hits;
                    }}
                    renderItem={(item, isActive) => {                       
                        return (<div className={`p-4 flex items-center leading-tight ${ isActive ? 'bg-slate-100' : 'border-transparent' }`}>
                            { item._index == 'persons' && <>
                                <div className="flex-1">
                                    <strong>{ item._source.company ? item._source.company : item._source.firstname + ' ' + item._source.lastname }</strong><br />
                                    { item._source.company && (item._source.firstname || item._source.lastname) && <div className="text-sm font-medium">
                                        { item._source.firstname } { item._source.lastname }
                                    </div> }
                                    <span className="text-sm">OPNR #{ item._id }</span>
                                    { item._source.verkehrsnummer && <span className="text-sm"> &bull; VN { item._source.verkehrsnummer }</span> }
                                </div>
                                <div className="bg-amber-300 py-1 px-5 ml-8 rounded-full text-center">{ item._source.types && item._source.types[0] ? personTypes[item._source.types[0]] : 'Person' }</div>
                            </> }

                            { item._index == 'books' && <>
                                <div className="flex-1">
                                    <strong>{ item._source.title }</strong><br />
                                    <div className="text-sm leading-tight">
                                        { hyphenate(item._source.isbn13) } &bull; { bookStatus[item._source.status] }
                                    </div>
                                </div>
                                <div className="bg-blue-200 py-1 px-5 ml-8 rounded-full text-center">Buch</div>
                            </> }

                            { item._index == 'orders' && <>
                                <div className="flex-1">
                                    <strong>{ item._source.code }</strong><br />
                                    <div className="text-sm leading-tight">
                                        { item._source.client_name } &bull; { orderStatus[item._source.status] }
                                    </div>
                                </div>
                                <div className="bg-green-200 py-1 px-5 ml-8 rounded-full text-center">Bestellung</div>
                            </> }

                            { item._index == 'invoices' && <>
                                <div className="flex-1">
                                    <strong>{ item._source.total >= 0 ? 'Rechnung' : 'Gutschrift' } { item._source.invoice_id }</strong><br />
                                    <div className="text-sm leading-tight">
                                        { item._source.client_name } &bull; { user.publishers.find(p => p.id == item._source.publisher_id)?.name } &bull; { formatCurrency(item._source.total) } &bull; { item._source.status == 'paid' ? 'Bezahlt' : 'Offen'}
                                    </div>
                                </div>
                                <div className="bg-violet-200 py-1 px-5 ml-8 rounded-full text-center">Rechnung</div>
                            </> }
                        </div>)
                    }}
                />

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 absolute text-slate-500 z-10 left-4 top-3">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>

            </div>

            <NotificationCenter />

            <ProfileDialog isOpen={profileDialogOpen} onClose={() => setProfileDialogOpen(false)} />

            <Dropdown
                className="!p-0 ml-3 lg:ml-6 !border-0"
                items={[
                    { label: 'Mein Konto', onClick: () => setProfileDialogOpen(true) },
                    { label: 'Abmelden', onClick: () => handleLogout() },
                ]}
                renderButton={() => (
                    <div className="flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-10 h-10 lg:w-12 lg:h-12 mr-2 fill-slate-500">
                            <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" clipRule="evenodd" />
                        </svg>
                        <div className="hidden lg:block">
                            <div className="text-lg leading-none text-slate-500">{ user.name }</div>
                            {/* <div className="text-slate-400 leading-none text-sm">Admin</div> */}
                        </div>
                    </div>
                )}
                dropdownClassName="min-w-40"
                itemClassName="!p-0"
                renderItem={({ label, onClick }) => (
                    <div className="px-4 py-2" onClick={onClick}>{ label }</div>
                )}
            />
        </div>
    )
}