import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { PusherProvider, useChannel } from "@harelpls/use-pusher";
import Pusher from 'pusher-js'
import ChangelogModal from "./components/ChangelogModal";
import BugDialog from "./components/BugDialog";

Pusher.logToConsole = true;

export default () => {

    const pusherConfig = {
        clientKey: "9fe1d961c270a628caaa",
        cluster: "eu",
        authEndpoint: import.meta.env.VITE_BROADCAST_AUTH,
        enabledTransports: ['ws', 'wss'],
        auth: {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        }
    };

    const contextClass = {
        success: "bg-green-600",
        error: "bg-red-600",
        info: "bg-gray-600",
        warning: "bg-orange-400",
        default: "bg-indigo-600",
        dark: "bg-white-600 font-gray-300",
    };

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [bugModalOpen, setBugModalOpen] = useState(false);
    
    return (
        <PusherProvider {...pusherConfig}>
            <button type="button" className="fixed bottom-5 right-5 z-[9999999] bg-slate-700 w-14 h-14 opacity-50 hover:opacity-100 transition-all hover:scale-110 rounded-full flex items-center justify-center" title="Hilfe erhalten" onClick={() => setBugModalOpen(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#fff" className='h-9 w-9'><path d="M9.97308 18H14.0269C14.1589 16.7984 14.7721 15.8065 15.7676 14.7226C15.8797 14.6006 16.5988 13.8564 16.6841 13.7501C17.5318 12.6931 18 11.385 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 11.3843 6.46774 12.6917 7.31462 13.7484C7.40004 13.855 8.12081 14.6012 8.23154 14.7218C9.22766 15.8064 9.84103 16.7984 9.97308 18ZM14 20H10V21H14V20ZM5.75395 14.9992C4.65645 13.6297 4 11.8915 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 11.8925 19.3428 13.6315 18.2443 15.0014C17.624 15.7748 16 17 16 18.5V21C16 22.1046 15.1046 23 14 23H10C8.89543 23 8 22.1046 8 21V18.5C8 17 6.37458 15.7736 5.75395 14.9992ZM13 10.0048H15.5L11 16.0048V12.0048H8.5L13 6V10.0048Z"></path></svg>
            </button>

            <div className="flex h-screen overflow-hidden">
                <Sidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />

                <div className="flex-1 flex flex-col overflow-hidden">
                    <Header onOpenSidebar={() => setSidebarOpen(true)} />

                    <main id="content" className="flex-1 overflow-x-hidden overflow-y-auto bg-slate-200">
                        <Outlet />
                    </main>
                </div>

                <ChangelogModal />

                <BugDialog isOpen={bugModalOpen} onClose={() => setBugModalOpen(false)} />

                <ToastContainer className="z-[999999]" position="bottom-right" toastClassName={({ type }) => contextClass[type || "default"] +
                    " relative flex p-3 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer text-white text-sm leading-tight z-[999999]"
                } />
            </div>
        </PusherProvider>
    );
};