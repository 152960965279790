export const bookTypes = {
    reprint: 'Reprint/Nachdruck',
    print_on_demand: 'Print On Demand',
    first_edition: 'Erstauflage',
    new_edition: 'Neuauflage',
    revised_edition: 'überarbeitete Auflage',
    non_book: 'Non-Book',
    ebook: 'eBook',
    dvd: 'DVD',
    video: 'Video',
    audio: 'Audio-CD',
    calendar: 'Kalender',
    digital: 'Digital',
    webinar: 'Webinar',
};

export const bookStatus = {
    shortage: 'fehlt kurzfristig - Bestellung notiert',
    out_of_stock: 'vergriffen -> Bestellung abgelegt',
    planned: 'in Planung - interne Daten',
    non_web: 'nonweb - interne Daten',
    discontinued: 'erscheint nicht - Titel eingestellt',
    blocked: 'Auslieferung gestoppt - Titel gesperrt',
    available: 'lieferbar',
    reprint: 'Nachdruck ohne Termin - vorgemerkt',
    new_edition: 'vergriffen --> siehe Neuauflage',
    available_without_vlb: 'Lieferbar - ohne VLB-Eintrag',
    pending: 'in Vorbereitung - Bestellung notiert'
}

export const bookFileTypes = {
    cover: 'Coveransicht',
    backcover: 'Backcover',
    sample_text: 'Leseprobe',
    table_of_contents: 'Inhaltsverzeichnis',
    author_image: 'Autorenbild',
    other: 'Sonstiges'
};

export const bindingTypes = {
    'softcover': 'Softcover (Klebeb.)',
    'softcover-thread': 'Softcover (Fadenh.)',
    'hardcover': 'Hardcover (Klebeb.)',
    'hardcover-thread': 'Hardcover (Fadenh.)',
    'loose-leaf': 'Loseblattsammlung',
    'spiral': 'Spiralbindung',
    'pamphlet': 'Heftbindung'
}

export const bookContractTypes = {
    publisher: 'Verlags-/Autorenvertrag',
    license: 'Lizenzvertrag',
    translator: 'Übersetzervertrag',
    editor: 'Herausgebervertrag',
    compound_author: 'Sammelvertrag',
    cooperation: 'Kooperationsvertrag',
    fulfilment: 'Auslieferungsvertrag',
    commision: 'Kommisionsvertrag'
}

export const contributorTypes = {
    main_author: 'Hauptautor',
    co_author: 'Coautor',
    editor: 'Herausgeber',
    translator: 'Übersetzer',
    licensor: 'Lizenzgeber',
    licensee: 'Lizenznehmer',
    agent: 'Agent'
}

export const priceTypes = {
    retail: 'gebundener Ladenpreis',
    free: 'freier Preis',
    subscription: 'Subskriptionspreis',
    bundle: 'Bundle-Preis',
    special: 'Sonderausgabenpreis'
};

export const orderTypes = {
    email: 'E-Mail',
    shop: 'Shop',
    phone: 'Telefon',
    fax: 'Fax',
    post: 'Post',
    ibu: 'IBU',
    zeitfracht: 'Zeitfracht',
    libri: 'Libri',
    brochure: 'Prospekt',
    ad: 'Anzeige',
    review: 'Bewertung',
    amazon: 'Amazon',
    other: 'Sonstige'
}

export const orderStatus = {
    pending: 'Offen',
    processing: 'In Bearbeitung',
    completed: 'Abgeschlossen',
    cancelled: 'Storniert'
}

export const personTypes = {
    consumer: 'Endkunde',
    retailer: 'Buchhändler',
    wholesaler: 'Barsortiment',
    author: 'Autor',
    publisher: 'Verlag',
    reviewer: 'Rezensent',
    other: 'Sonstige'
}

export const royaltyTypes = {
    retail: 'Netto-Ladenpreis (NLP)',
    revenue: 'Netto-Erlös (NVAP)'
}

export const royaltyIntervals = {
    halfyearly: 'halbjährlich',
    yearly: 'jährlich'
}

export const bookCostsTypes = {
    production: 'Herstellung',
    composition: 'Satz',
    editorial: 'Lektorat',
    graphics: 'Grafik',
    license: 'Lizenzen',
    translation: 'Übersetzung',
    royalty: 'Marketing'
}

export const stockTransactionTypes = {
    inbound: "Buchaktivierung",
    outbound: "Buchentnahme",
    inbound_by_invoice: "Buchaktivierung (Rechnung)",
    outbound_by_invoice: "Buchentnahme (Rechnung)",
    transfer: "Umbuchung",
    transfer_by_invoice: "Umbuchung (Rechnung)",
    delivery: "Lieferung",
    return: "Rückgabe",
}

export const addressTypes = {
    shipping: 'Lieferanschrift',
    billing: 'Rechnungsanschrift',
    private: 'Privatadresse',
    headquarter: 'Firmensitz',
    advertisement: 'Werbung',
    business: 'Dienstadresse',
    author: 'Autorenanschrift',
    store: 'Filiale',
    editor: 'Herausgeberanschrift',
    creditor: 'Kreditorenanschrift',
    accounting: 'Buchhaltung'
}