interface LoaderProps {
    text?: string,
    classNames?: string,
    size?: number,
    color?: string
}

const Loader = (props: LoaderProps) => (
    <div className={'w-full h-full flex justify-center items-center text-xl ' + props.classNames}>
        <div className="flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={props.size} height={props.size} className="mr-5 animate-spin"><path fill="none" d="M0 0h24v24H0z"/><path fill={props.color} d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z"/></svg>
            { props.text && <div>{ props.text }</div> }
        </div>
    </div>
)

Loader.defaultProps = {
    text: 'Wird geladen...',
    size: 40,
    color: '#475569'
}

export default Loader;