import axios from "axios";
import { useAuth } from "./useAuth";

const ELASTIC_BASE = import.meta.env.VITE_ELASTIC_BASE
    ? import.meta.env.VITE_ELASTIC_BASE
    : import.meta.env.VITE_API_BASE + '/elastic';

const useElastic = () => {
    const { publishers } = useAuth();

    const search = async ({ index, body }) => {
        const result = await axios.post(`${ ELASTIC_BASE }/${ index }/_search`, body, {
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: "elastic",
                password: "westarp"
            }
        });

        return result.data;
    };

    const count = async ({ index, body }) => {
        const result = await axios.post(`${ ELASTIC_BASE }/${ index }/_count`, body, {
            headers: {
                "Content-Type": "application/json"
            },
            auth: {
                username: "elastic",
                password: "westarp"
            }
        });

        return result.data;
    };

    const searchBooks = (term: string, limit?: number, availableOnly: boolean = false) => {
        const query = {
            bool: {
                must: [],
                should: term.split(' ').map(word => [
                    { query_string: { query: word } },
                    // { term: { _id: { value: word, boost: 10 } } },
                    { wildcard: { isbn13: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 10 } } },
                    { wildcard: { isbn10: { value: "*" + word.replace(/[^0-9]/g, '') + "*", boost: 10 } } },
                    { wildcard: { authors: "*" + word + "*" } },
                    { term: { status: { value: 'available', boost: 0.5 } } }
                ]).flat(),
            }
        };

        if (availableOnly) {
            query.bool.must.push({ terms: { status: ['available', 'planned', 'non_web', 'pending'] } });
        }

        query.bool.must.push({ terms: { publisher_id: publishers.map(publisher => publisher.id) } });

        return search({
            index: 'books',
            body: {
                min_score: 1,
                size: limit || 10,
                query,
                sort: [
                    { _score: { order: 'desc' } },
                ]
            }
        });
    };

    const searchPersons = (term, limit) => {
        return search({
            index: 'persons',
            body: {
                min_score: 1,
                query: {
                    bool: {
                        should: term.split(' ').map(word => [
                            { query_string: { query: word } },
                            { term: { _id: { value: word, boost: 10 } } },
                            { term: { verkehrsnummer: word } },
                            { fuzzy: { company: word }},
                            { fuzzy: { firstname: { value: word } } },
                            { fuzzy: { lastname: { value: word } } }
                        ]).flat(),
                    }
                }
            }
        });
    }

    return {
        searchBooks,
        searchPersons,
        search,
        count
    }
};

export default useElastic;