import classNames from 'classnames';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNotificationCenter } from "react-toastify/addons/use-notification-center"
import useApi from '@/hooks/useApi';
import { useAuth } from '@/hooks/useAuth';
import parse from 'html-react-parser';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { de } from 'date-fns/locale';
import { NavLink, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { useChannel } from '@harelpls/use-pusher';
import { toast } from 'react-toastify';

const NotificationCenter = () => {
    const api = useApi();
    const { user, publishers, updatePublisherTerms } = useAuth();
    const { notifications, add, markAsRead } = useNotificationCenter();
    const [open, setOpen] = useState(false);
    const dropdownRef = useRef();
    const navigate = useNavigate();

    const channel = useChannel('private-App.Models.User.' + user.id);
    
    useEffect(() => {
        api.get('user/notifications').then((response) => {
            response.data.forEach((notification) => {
                add({
                    id: notification.id,
                    content: parse(notification.data.title),
                    createdAt: parseISO(notification.created_at),
                    containerId: 'notification-center',
                    data: {
                        link: notification.data?.link
                    }
                });
            });
        });

        
    }, []);

    useEffect(() => {
        if (channel) {
            channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {
                toast(({ closeToast, toastProps }) => (
                    <div>{ parse(data.title) }</div>
                ), {
                    type: 'success',
                    toastId: data.id,
                    containerId: 'notification-center',
                    data: {
                        link: data.link
                    },
                    onClick: () => {
                        if (data.link) {
                            markAsRead(data.id);
                            navigate(data.link);
                        }
                    }
                });
            });

            channel.bind('App\\Events\\Publisher\\PublisherTermsChanged', (data) => {
                updatePublisherTerms(data.publisher_id, data.terms);
            });
        }
    }, [channel]);

    useOnClickOutside(dropdownRef, () => setOpen(false));

    const unreadNotifications = useMemo(() => notifications.filter((notification) => !notification.read && notification.containerId === 'notification-center'), [notifications]);
    const timeAgo = date => formatDistanceToNow(new Date(date), { locale: de, addSuffix: true });

    const markRead = (id) => {
        markAsRead(id);

        api.post('user/notifications/mark-as-read', { id });
    }

    const notificationsList = unreadNotifications.map((notification, index) => (
        <div key={index} className={"p-3 border-b border-gray-200 hover:bg-slate-50 flex items-center " + (notification.data?.link ? 'cursor-pointer' : '')} onClick={e => {
            if (notification.data?.link) {
                e.preventDefault();
                markRead(notification.id);
                navigate(notification.data.link);
                setOpen(false);
            }
        }}>
            <div>
                <div className='leading-tight'>{ notification.content }</div>
                <div className="text-slate-400 text-sm">{ timeAgo(notification.createdAt) }</div>
            </div>
            <div className="ml-auto pl-4">
                <button type='button' className='p-1 border border-slate-200 rounded-full hover:bg-slate-200' onClick={(e) => {
                    e.stopPropagation();
                    markRead(notification.id)
                }}>
                    <svg className="w-4 h-4 ml-auto text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
            </div>
        </div>
    ));

    return (
        <div className="relative ml-auto">
            <button 
                className="relative text-slate-800 cursor-pointer p-3"
                onClick={() => setOpen(true)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 lg:w-8 lg:h-8">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
                <div className="bg-red-600 text-white rounded-full w-5 h-5 text-center text-sm absolute right-0 bottom-0 ">{unreadNotifications.length}</div>
            </button>
            <AnimatePresence>
                { open && <motion.div
                    ref={dropdownRef}
                    className="absolute -right-3 top-full mt-0 bg-white shadow-2xl text-sm z-20 w-96 rounded overflow-hidden leading-tight"
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                >
                <div className="px-3 py-3 border-b bg-slate-100 border-slate-300 flex items-center">
                    <div className="text-lg font-semibold m-0">Benachrichtigungen</div>
                    <a href="#" className="ml-auto text-slate-400 hover:text-slate-500" onClick={(e) => {
                        e.preventDefault();
                        api.post('user/notifications/mark-all-as-read')
                        notifications.forEach((notification) => {
                            markAsRead(notification.id);
                        });
                    }}>Alle löschen</a>
                </div>
                { 
                    unreadNotifications.length === 0 ? (
                        <div className="p-3 text-center text-gray-400">Keine Benachrichtigungen</div>
                    ) : (
                        notificationsList 
                    )
                }</motion.div> }
            </AnimatePresence>
        </div>
    )
}

export default NotificationCenter;