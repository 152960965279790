import classNames from "classnames";
import { useSelect } from "downshift";
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';

const Dropdown = (props) => {
    // const [selectedItem, setSelectedItem] = useState(props.items.find(item => item.value === props.value));
    const itemToString = (item) => (item ? item.name : "");

    // const selectedItem = props.items.find((item) => item[props.itemValueKey] === props.value) || null;

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        highlightedIndex,
        getItemProps,
    } = useSelect({
        items: props.items,
        itemToString,
        selectedItem: props.selectedItem,
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            // setSelectedItem(newSelectedItem);
            props.onSelect(newSelectedItem);
        }
    })

    return (
        <div className={classNames([ "relative text-left", props.containerClassName])}>
            <div>
                <button aria-label="toggle menu" className={classNames([
                    'block w-full rounded-md bg-white px-4 py-2 text-slate-700 border border-slate-200 transition text-left',
                    props.className
                ])} type="button" {...getToggleButtonProps()}>
                    { props.renderButton ? (
                        props.renderButton({ selectedItem: props.selectedItem, isOpen})
                    ) : (props.selectedItem ? props.selectedItem[props.itemLabelKey] : props.placeholder)}
                </button>
            </div>
            <ul
                {...getMenuProps()}
                className={classNames(
                    'absolute right-0 z-10 mt-2 w-full origin-top-right divide-y bg-white rounded-md border border-slate-200 shadow-lg py-2 transition-all overflow-auto max-h-80 divide-slate-200 scrollbar-thin scrollbar-thumb-slate-400 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollback-thumb-rounded-full',
                    {
                        '-translate-y-3 invisible opacity-0': !isOpen,
                        'translate-y-0 visible opacity-100': isOpen
                    },
                    props.dropdownClassName
                )}>
                {isOpen &&
                    props.items.map((item, index) => (
                    <li key={index} {...getItemProps({item, index})}
                        className={classNames(
                            'text-slate-700 block px-4 py-2 hover:bg-slate-100 transition-all cursor-pointer',
                            props.itemClassName,
                        )}
                    >
                        { props.renderItem ? (
                            props.renderItem(item)
                        ) : item[props.itemLabelKey] }
                    </li>
                    ))}
                </ul>
        </div>
    );
}

Dropdown.propTypes = {
    items: PropTypes.array,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    itemLabelKey: PropTypes.string,
    itemValueKey: PropTypes.string,
    placeholder: PropTypes.string,
    renderItem: PropTypes.func,
    renderButton: PropTypes.func,
    onSelect: PropTypes.func
};

Dropdown.defaultProps = {
    items: [],
    className: '',
    itemLabelKey: 'label',
    itemValueKey: 'value',
    placeholder: 'Auswählen',
    renderItem: null,
    renderButton: null,
    onSelect: () => {}
}

export default Dropdown;